<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >

  <v-row>
    <v-col
    cols="12"
    sm="12"
    lg="12"
    >
      <v-btn color="disabled" to="/report/radm-1" stacked variant="tonal">
        <v-icon>mdi-chart-line</v-icon>&nbsp;Report 1
      </v-btn>
      <v-btn color="disabled" to="/report/radm-2" stacked variant="tonal">
        <v-icon>mdi-chart-bar</v-icon>&nbsp;Report 2
      </v-btn>
      <v-btn color="disabled" to="/report/radm-3" stacked variant="tonal">
        <v-icon>mdi-chart-line</v-icon>&nbsp;Report 3
      </v-btn>
      <v-btn color="warning" to="/report/radm-4" stacked variant="tonal">
        <v-icon>mdi-chart-bar</v-icon>&nbsp;Report 4
      </v-btn>
      <v-btn color="disabled" to="/report/radm-5" stacked variant="tonal">
        <v-icon>mdi-chart-line</v-icon>&nbsp;Report 5
      </v-btn>
  </v-col>
</v-row>

<v-row>
  <v-col cols="6">
    <v-combobox
      v-model="comboBoxValue"
      :items="listOrg"
      item-text="directorate"
      item-value="unit_code"
      label="Pilih Organisasi"
      placeholder="Organization"
      @change="(event) => updateOrg(event)"
    >
    </v-combobox>
  </v-col>

  <v-col
    cols="3"
    sm="3"
  >
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="selDate"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selDate"
          label="Filter by Month"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="selDate"
        type="month"
        range
        scrollable
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="filterDate(selDate)"
          
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </v-col>
  <v-col cols="3" md="3">
    <v-btn color="warning" @click="resetFilterDate()">
      Reset
    </v-btn>
    <v-btn color="primary">
      <download-excel :data="listDataReport" color="primary" stacked variant="tonal" name="freq_penolakan_approval_eoffice.xls">
        Download Excel&nbsp;<v-icon>mdi-download</v-icon>
      </download-excel>
    </v-btn>
  </v-col>
  <v-col cols="3" md="3">
    &nbsp;
  </v-col>
</v-row>

    <v-row>
      <v-col
      cols="12"
      sm="12"
      lg="12"
      >
      
      <base-material-card
                      icon="mdi-chart-bar"
                      title="DASHBOARD FREKUENSI PENOLAKAN APPROVAL (BATAS WAKTU)"
                      class="px-5 py-3"
                      >

                      <v-row>
                        <v-col cols="12"
                        md="12">
                        <apexchart width="95%" height=500px type="line" :options="optReport" :series="seriesReport"></apexchart>
                        </v-col>
                        
                        <v-col cols="12"
                        md="12">
                          <v-data-table
                            :headers="headers_report"
                            :items="listDataReport"
                            class="elevation-1"
                          >

                          <template v-slot:item.fullname="{ item }">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" @click="openDetil(item)" >
                                    {{item.fullname}}
                                  </v-btn>
                                </template>
                                <span>Open Detail</span>
                              </v-tooltip>
                            </template>
                            
                          </v-data-table>
                        </v-col>
                      </v-row>

                    </base-material-card>

      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

  </v-container>
</template>

<script>

  import moment from 'moment'
  import secureStorage from '@/utils/secureStorage'
  import {getDBInfoReport4 } from '@/api/report'

  export default {
    name: 'admReport4',
    components: { },
    data () {
      return {
        toast: { show: false, color:'green', text:'', timeout: 2000 },
        isLoading: false,
        listDataReport: [],
        listLoading: false,
        tableOpt: {},

        headers_report: [
        {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Name', value: 'fullname', align: 'center',  sortable: true, class:'subtitle-1' },
          { text: 'Letter Not Approve', value: 'letter_not_approved', align: 'center',  sortable: true, class:'subtitle-1' },
          { text: 'Struktur', value: 'pos_name', align: 'center',  sortable: true, class:'subtitle-1' },
          { text: 'Month', value: 'month', align: 'center',  sortable: true, class:'subtitle-1' },
          { text: 'Year', value: 'period_year', align: 'center',  sortable: true, class:'subtitle-1' },
        ],
        dataUser: null,
        Jabatan: '-',
        
        optReport: {
          chart: {
            id: 'vchart-report4',
            type: 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
          },
          dataLabels: {
            enabled: true,
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 1
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            categories: [],
            title: {
                text: 'Year/Month'
              }
          },
          yaxis: {
            title: {
              text: 'Frekuensi'
            }
          },
        },
        seriesReport: [],
        multiSearch: {},
        masterData: [],
        selDate: [],
        menu: false,
        listOrg: [],
        setValue: false,
        defaultValue: "Pilih Organisasi",
        
      }
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).add(7, 'hours').format('DD MMM YYYY')
        return sdate
      },
      formatDatetime: function (value) {
        if (!value) return ''
        var sdate = moment(value).add(7, 'hours').format('DD/MM/YYYY HH:mm')
        return sdate
      }
    },
    destroyed () {
    },
    created () {
      const vm = this
      vm.currrole = secureStorage.getItem('currRole')
      console.log(vm.currrole)

      var holding = vm.currrole.hrorganization['holding']
      var level = vm.currrole.hrorganization['level']
      var rolename = vm.currrole.rolename
      
      var newFiltered = []
      var strsel = vm.currrole.hrorganization['unit_code'].toString()
      var cstrsel = strsel.substring(0,6)

      vm.listOrg = secureStorage.getItem('orgall')
      vm.listOrg = vm.listOrg.filter( i => holding.includes( i.holding ) );

      // console.log(level, strsel)
      if(level == "Main_PD" || level == "Main_VPD" || rolename == "komite"){
        vm.listOrg = vm.listOrg.filter( i =>  i.level.includes("Direktorat") || i.level.includes("Main_PD"));
        cstrsel = strsel.substring(0,2)

      }else if(level == "Direktorat"){
        vm.listOrg = vm.listOrg.filter( i => i.level.includes("Biro") || i.level.includes("Region") || i.level.includes("Departement"));
        cstrsel = strsel.substring(0,4)
      }else{
        vm.listOrg = []
      }

      var lencstrsel = cstrsel.length
      // console.log("created cstrsel", cstrsel, lencstrsel)

      var array = vm.listOrg
      array.forEach(element => {
        let strucode = element.unit_code.toString()
        let org5 = strucode.substring(0, lencstrsel)
        
        if(cstrsel == org5){
          console.log(cstrsel + "===" + org5)
          newFiltered.push(element)
        }
      })

      vm.listOrg = newFiltered
      
      // console.log("new listOrg", vm.listOrg)
      vm.refresh()
      
    },
    mounted () {
      const vm = this
      vm.isLoading = true
      setTimeout(function(){
        console.log("delay default combo")
        vm.defaultValue = vm.listOrg[0]
        vm.updateOrg(vm.defaultValue)
        vm.isLoading = false
      },5000); 
    },
    watch: {
      tableOpt: {
        handler () {
          if(!this.listLoading) this.refresh()
        },
        deep: true,
      },
    },
    computed: {
      comboBoxValue: {
        get() {
          if (!this.setValue) {
            return this.defaultValue;
          } else {
            return this.setValue;
          }
        },
        set(val) {
          if(val == null){
            console.log("cbox empty");
          }else{
            console.log("cbox", val);
            this.updateOrg(val);
            this.setValue = val;
          }
        }
      }
    },
    methods: {
      refresh() {
        const vm = this

        if(vm.currrole.hrorganization){
          var companyName = vm.currrole.hrorganization['structure_name']
          var holding = vm.currrole.hrorganization['holding']
          console.log(holding)
 
          vm.getReport(companyName, holding)
        }
        
      },
      openDetil(obj){
        const vm = this

        vm.isLoading = true
        console.log("openDetil", obj)

        this.$router.push('list4/'+obj.id+'/' +obj.period_month+ '/' + obj.period_year) 
        vm.isLoading = false
      },
      resetFilterDate(){
        const vm = this;
        console.log("resetFilterDate");
        vm.selDate = []
        vm.updateOrg(vm.selValueOrg)
      },
      async filterDate(objDate){
        const vm = this;
        vm.$refs.menu.save(objDate)
        console.log("filterDate", objDate);
        vm.listLoading = true 

        if(objDate){
          var array = this.listDataReport
          var newarray = []

          for (var m = moment(objDate[0],'YYYY-M'); m.isSameOrBefore(moment(objDate[1],'YYYY-M')); m.add(1, 'month')) {
            // console.log(m);
            if(array){
              array.forEach(element => {
                let selStartM = moment(m).format('YYYY/M')
                let monthPeriod = moment(element.period_year + '/' + element.period_month).format('YYYY/M')
  
                if(selStartM == monthPeriod){
                  newarray.push(element)
                }
              })

              vm.drawReport(newarray)
              vm.drawTable(newarray)
            }
          }         
          vm.listLoading = false 
        }
      },

      updateOrg(value){
        const vm = this

        vm.isLoading = true
        console.log("updateOrg", value)
        
        let newFiltered = []
        var level = vm.currrole.hrorganization['level']
        var datalevel = ''
        if(value != null){
          datalevel = value.level
        }
        let array = vm.masterData

        if(value){
          vm.selValueOrg = value
          var strsel = value.directorate.toString()

          array.forEach(el => {
            let strstrucName = el.directorate.toString()
            if(strsel == strstrucName){
              newFiltered.push(el)
            }
          })

          vm.listDataReport = newFiltered
          // console.log("newFiltered", newFiltered)
          vm.drawReport(newFiltered)
          vm.drawTable(newFiltered)
        }
        
        vm.isLoading = false
      },

      async getReport(companyName, holding) {

        const vm = this
        vm.listLoading = true

        getDBInfoReport4(companyName, holding).then(response => {
          var array = response.data
          if(array){
            vm.masterData = array
            // console.log("masterData", vm.masterData)

            var listStructure = []
            array.forEach(element => {
              var temp = {
                "directorate":element.directorate
              }
              listStructure.push(temp)
            })

            //Remove duplication
            vm.listOrg = listStructure.reduce((unique, o) => {
                            if(!unique.some(obj => obj.directorate === o.directorate)) {
                              unique.push(o);
                            }
                            return unique;
                          },[]);

            // console.log("new listOrg", vm.listOrg)
          }else{
            vm.masterData = []
          }
          vm.listLoading = false
        })
      },
      
      async drawReport(objData) {

        const vm = this
        vm.listLoading = true
        var array = objData
        // console.log(array)

        //DRAW CHART =======
        var periodTime = []
        var employeeName = []
        array.forEach(element => {
          let monthPeriod = moment(element.period_year + '/' + element.period_month).format('YYYY/M')
          let eName = element.fullname + ' ' + element.pos_name

          periodTime.push(monthPeriod)
          employeeName.push(eName)
        })

        //Remove duplication
        periodTime = periodTime.filter((item,idx) => periodTime.indexOf(item) === idx); 
        //Ascending
        periodTime = periodTime.sort((a, b) => moment(a, 'YYYY/M').isBefore(moment(b, 'YYYY/M')) ? -1 : 1,)
        employeeName = employeeName.filter((item,idx) => employeeName.indexOf(item) === idx);  

        //Reformat Textview MMM-YYYY
        var tmpperiodTime = []
        periodTime.forEach(idate => {
          var nDate = moment(idate, 'YYYY/M').format('MMM-YYYY')
          tmpperiodTime.push(nDate)
        })
        periodTime = tmpperiodTime

        vm.optReport= {
          xaxis: {
            categories: periodTime
          }
        }

        var dataSeries = []
        employeeName.forEach(iname => {

          var datax = []
          
          var flag = 0
          periodTime.forEach(time => {
            flag = 0

            array.forEach(element => {
              console.log(element)
              let employeeName = element.fullname + ' ' + element.pos_name
              let monthPeriod = moment(element.period_year + '/' + element.period_month).format('MMM-YYYY')

              if(employeeName == iname && time == monthPeriod){
                let myVar = element.letter_not_approved
                datax.push(myVar);
                flag = 1;
              }
            })
            if(flag ==0){
              datax.push(0);
            }
          })

          var seri = { name: iname, data: datax}
          dataSeries.push(seri)
        })

        // console.log("dataSeries", dataSeries)
        vm.seriesReport = dataSeries
        vm.listLoading = false
      },
      async drawTable(objData) {
        const vm = this
        var filtereddata = []

        //DRAW TABLE
        objData.forEach(element => {
          // console.log(element)
          element['month'] = moment(element.period_month,'M').format('MMM');
          filtereddata.push(element)
        })
        vm.listDataReport = filtereddata
      },

    },
  }
</script>

<style>
.centered-input input {
  text-align: center
}
</style>